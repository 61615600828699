import { Injectable, computed, inject } from '@angular/core';
import { QuotesStateService } from '@stores/quotes.state.service';
import { CerbosPrincipalService } from '../../cerbos-principal.class';
import { CerbosResource } from '../../cerbos.policies';

@Injectable({
	providedIn: 'root'
})
export class QuotePermissionsService extends CerbosPrincipalService {
	readonly quotesState = inject(QuotesStateService);

	readonly resource = computed(() => {
		const quote = this.quotesState.currentQuote();

		return {
			kind: CerbosResource.Quote,
			attr: { quote }
		};
	});
}
